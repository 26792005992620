import '../styles/globals.css';
import 'flatpickr/dist/themes/airbnb.css';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Head from 'next/head';
import Router from 'next/router';
import getConfig from 'next/config';

import { RecoilRoot } from 'recoil';

import * as Sentry from '@sentry/node';

import { RewriteFrames } from '@sentry/integrations';

import Satellite from '../store/satellite'

import NProgress from 'nprogress';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

NProgress.configure({ minimum: 0.15, showSpinner: false, trickleSpeed: 1000 });

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

import Meta from '@components/Meta';

import OddularTheme from '../theme';

const theme = extendTheme(OddularTheme);

const App = ({ Component, pageProps }) => {
  return (
    <RecoilRoot>
      <ChakraProvider theme={theme}>
        <Satellite />
        <Head>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
            rel="stylesheet"
          />
        </Head>
        <Meta />
        <Component {...pageProps} />
      </ChakraProvider>
    </RecoilRoot>
  );
}

export default App;
