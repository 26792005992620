export interface ColorHues {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export type Colors = typeof colors;

const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#1b1f23',
  white: '#ffffff',
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.02)',
    100: 'rgba(0, 0, 0, 0.04)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },
  blueGray: {
    50: '#F8FAFC',
    100: '#F1F5F9',
    200: '#E2E8F0',
    300: '#CBD5E1',
    400: '#94A3B8',
    500: '#64748B',
    600: '#475569',
    700: '#334155',
    800: '#1E293B',
    900: '#0F172A',
  },
  coolGray: {
    50: '#F9FAFB',
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D1D5DB',
    400: '#9CA3AF',
    500: '#6B7280',
    600: '#4B5563',
    700: '#374151',
    800: '#1F2937',
    900: '#111827',
  },
  gray: {
    50: '#fafafa',
    100: '#f6f6f6',
    200: '#e1e1e1',
    300: '#d1d1d1',
    400: '#b9b9b9',
    500: '#a0a0a0',
    600: '#939393',
    700: '#747474',
    800: '#424242',
    900: '#141414',
  },
  dark: {
    50: '#ebebec',
    100: '#d7d7da',
    200: '#afb0b5',
    300: '#5f616a',
    400: '#4b4d58',
    500: '#373945',
    600: '#212330',
    700: '#2c2e37',
    800: '#272830',
    900: '#16171c',
  },
  blue: {
    50: '#dcf4ff',
    100: '#9dd8ff',
    200: '#76c8ff',
    300: '#4eb8ff',
    400: '#27a9ff',
    500: '#0099FF',
    600: '#0081d8',
    700: '#006ab1',
    800: '#005289',
    900: '#003b62',
  },
  blueAlpha: {
    50: 'rgba(2, 122, 255, 0.02)',
    100: 'rgba(2, 122, 255, 0.04)',
    200: 'rgba(2, 122, 255, 0.08)',
    300: 'rgba(2, 122, 255, 0.16)',
    400: 'rgba(2, 122, 255, 0.24)',
    500: 'rgba(2, 122, 255, 0.36)',
    600: 'rgba(2, 122, 255, 0.48)',
    700: 'rgba(2, 122, 255,0.64)',
    800: 'rgba(2, 122, 255, 0.80)',
    900: 'rgba(2, 122, 255, 0.92)',
  },
  blueGradient: {
    50: 'rgba(2, 122, 255, 0.02)',
    100: 'rgba(2, 122, 255, 0.04)',
    200: 'rgba(2, 122, 255, 0.08)',
    300: 'linear-gradient( 170deg, #0fe659 0%, #27a9ff 50%, #6B3DE5 100% )',
    400: 'linear-gradient( 40deg, #0FECF9 0%, #6B3DE5 50%, #fa78c9 100% )',
    500: 'linear-gradient(to right, #0099FF, #27a9ff)',
    600: 'rgba(2, 122, 255, 0.48)',
    700: 'rgba(2, 122, 255,0.64)',
    800: 'rgba(2, 122, 255, 0.80)',
    900: 'rgba(2, 122, 255, 0.92)',
  },
  green: {
    50: '#effae0',
    100: '#d6f1ad',
    200: '#bde97b',
    300: '#ace359',
    400: '#9bde37',
    500: '#88CC22',
    600: '#72aa1c',
    700: '#5b8917',
    800: '#456711',
    900: '#2e450c',
  },

  purple: {
    50: '#F1ECFF',
    100: '#E3D9FF',
    200: '#C8B4FF',
    300: '#AD8EFF',
    400: '#9269FF',
    500: '#7744FF',
    600: '#6B3DE5',
    700: '#532FB2',
    800: '#3B227F',
    900: '#23144C',
  },
  yellow: {
    50: '#fffdef',
    100: '#fffbdd',
    200: '#fff5b1',
    300: '#ffea7f',
    400: '#ffdf5d',
    500: '#ffd334',
    600: '#f9c513',
    700: '#dbab09',
    800: '#b08800',
    900: '#735c0f',
  },
  orange: {
    50: '#fff8f2',
    100: '#ffebda',
    200: '#ffd1ac',
    300: '#ffab70',
    400: '#fb8532',
    500: '#f66a0a',
    600: '#e36209',
    700: '#d15704',
    800: '#c24e00',
    900: '#a04100',
  },
  red: {
    50: '#ffeef0',
    100: '#ffdce0',
    200: '#fdaeb7',
    300: '#f97583',
    400: '#ea4a5a',
    500: '#d73a49',
    600: '#cb2431',
    700: '#b31d28',
    800: '#9e1c23',
    900: '#86181d',
  },
  pink: {
    50: '#ffeef8',
    100: '#fedbf0',
    200: '#f9b3dd',
    300: '#f692ce',
    400: '#fa78c9',
    500: '#e141b5',
    600: '#c83aa1',
    700: '#af328d',
    800: '#7d2465',
    900: '#641d50',
  },
  teal: {
    50: '#ddfaed',
    100: '#ccf7e4',
    200: '#aaf2d1',
    300: '#88edbf',
    400: '#44e29a',
    500: '#22DD88',
    600: '#1dbb73',
    700: '#18995e',
    800: '#127749',
    900: '#0d5534',
  },
  cyan: {
    50: '#EDFDFD',
    100: '#C4F1F9',
    200: '#9DECF9',
    300: '#76E4F7',
    400: '#0BC5EA',
    500: '#00B5D8',
    600: '#00A3C4',
    700: '#0987A0',
    800: '#086F83',
    900: '#065666',
  },
};

export default colors;
